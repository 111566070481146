import { Box, CircularProgress, SxProps, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface PageLoaderProps {
  sx?: SxProps;
}
const PageLoader: FC<PageLoaderProps> = ({ sx }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
        textAlign: 'center',
        ...sx,
      }}
    >
      <Box sx={{ position: 'relative', width: '132px', height: '132px', m: 1, boxShadow: 1, borderRadius: '100%' }}>
        <CircularProgress
          color="secondary"
          size="132px"
          sx={{ borderRadius: '100%', bgcolor: 'other.plainWhite' }}
          thickness={5}
        />
        {/* Inner circle for an inset shadow and white loader background */}
        <CircularProgress
          color="secondary"
          size="106px"
          sx={{
            position: 'absolute',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            margin: 'auto',
            borderRadius: '100px',
            bgcolor: 'background.default',
          }}
          variant="determinate"
        />
      </Box>
      <Typography color="text.secondary" marginTop={2} variant="body/regular">
        {t('common.loading.msg')}
      </Typography>
    </Box>
  );
};

export default PageLoader;
