import { FC, PropsWithChildren, SetStateAction, useCallback } from 'react';

import i18n from '~/i18n';
import { setSentryUser } from '~/sentryCreateBrowserRouter';

import { LocalStorageKey, useLocalStorage } from '../useLocalStorage';
import { Auth, AuthContext, authSchema, globalAuth } from './AuthContext';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [auth, setAuth] = useLocalStorage<Auth | null>(LocalStorageKey.Auth, null, authSchema);

  /** SetAuth wrapper to also set the globalAuth value (that is used by Axios) */
  const setAuthWrapper = useCallback(
    (action: SetStateAction<Auth | null>) => {
      setAuth((curr) => {
        const newAuth = action instanceof Function ? action(curr) : action;
        globalAuth.value = newAuth;
        setSentryUser(newAuth?.user);

        // Only re-load i18n resources if the accessToken or webshopKey changed
        if (curr?.accessToken !== newAuth?.accessToken || curr?.webshopKey !== newAuth?.webshopKey) {
          i18n.reloadResources();
        }

        return newAuth;
      });
    },
    [setAuth],
  );

  return <AuthContext.Provider value={{ auth, setAuth: setAuthWrapper }}>{children}</AuthContext.Provider>;
};
