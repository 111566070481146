import { z } from 'zod';

export const filterValueSchema = z.object({
  key: z.string(),
  name: z.string(),
  timePeriod: z.string().nullable(),
});

export const filterSchema = z.object({
  field: z.string(),
  name: z.string(),
  defaultValue: z.string().nullable(),
  isRequired: z.boolean(),
  values: z.array(filterValueSchema),
});

export type FilterValue = z.infer<typeof filterValueSchema>;
export type Filter = z.infer<typeof filterSchema>;
