import { z } from 'zod';

export const filterGroupObjectTypeSchema = z.enum(['locations', 'courses']);

export const filterGroupSchema = z.object({
  icon: z.string().nullable(),
  id: z.number().int().positive(),
  name: z.string(),
  objectType: filterGroupObjectTypeSchema,
});

export type FilterGroup = z.infer<typeof filterGroupSchema>;
