import z from 'zod';

import { cartLineActionSchema } from '../schemas/action.schema';
import { contactSchema, guestContactSchema } from '../schemas/contact.schema';
import { messageSchema } from '../schemas/message';
import { playerCountSchema } from '../schemas/utils.schema';

export const cartLineType = z.enum(['reservation']);

export const cartLineResponse = z.object({
  actions: z.array(cartLineActionSchema),
  dateFrom: z.string().nullable(),
  dateTimeView: z.string().nullable(),
  dateTo: z.string().nullable(),
  description: z.string().nullable(),
  duration: z.string().nullable(),
  key: z.string().nullable(),
  maxGuestCount: z.number().nullable(),
  messages: z.array(messageSchema),
  playerCount: playerCountSchema,
  players: z.array(z.union([contactSchema, guestContactSchema])),
  statusCode: z.string().nullable(),
  statusName: z.string().nullable(),
  timeFrom: z.string().nullable(),
  timeTo: z.string().nullable(),
  type: cartLineType.nullable(),
  typeName: z.string().nullable(),
});
