import { z } from 'zod';

import { itemBaseSchema } from './item.schema';
import { addDatesTransformFn, dayOfWeekSchema, playerCountSchema } from './utils.schema';

export const quickItemSchema = itemBaseSchema
  .extend({
    dayOfWeek: dayOfWeekSchema,
    filterGroupId: z.string(),
    locationGroupCode: z.string(),
    maxGuestCount: z.number().int().min(0),
    playerCount: playerCountSchema,
    sportCode: z.string(),
    startTimeBlock: z.string().nullable(),
    timePeriod: z.string().nullable(),
  })
  .transform(addDatesTransformFn);

export type QuickItem = z.infer<typeof quickItemSchema>;
