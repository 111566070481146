import z from 'zod';

export const messageSchema = z.object({
  message: z.string(),
  messageShort: z.string().nullish(),
  position: z.string(),
  severity: z.union([z.literal('error'), z.literal('success'), z.literal('warning'), z.literal('info')]),
});

export type Message = z.infer<typeof messageSchema>;
