import z from 'zod';

import { languageCodeSchema } from '../schemas/utils.schema';

export const baseSsoUser = z.object({
  accessToken: z.string(),
  languageCode: languageCodeSchema,
  initialView: z.enum(['dashboard', 'list', 'schematic']),
  initialFilterGroupId: z.string().nullable(),
  section: z.string(),
  webshopKey: z.string(),
});

export const guestSsoUser = baseSsoUser.merge(
  z.object({
    cartContactType: z.literal('external'),
    contactId: z.string().nullable(),
    contactName: z.literal(null),
  }),
);

export const authenticatedSsoUser = baseSsoUser.merge(
  z.object({
    cartContactType: z.literal('internal'),
    contactId: z.string(),
    contactName: z.string(),
  }),
);

export const ssoResponse = z.union([guestSsoUser, authenticatedSsoUser]);

export type SsoResponse = z.infer<typeof ssoResponse>;
