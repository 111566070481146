import { de, enGB, fr, nl } from 'date-fns/locale';
import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { apiClient } from './api/api';
import { globalAuth } from './hooks/useAuth/AuthContext';

const fallbackLng = 'nl';

const lngs: {
  [code: string]: {
    nativeName: string;
    locale: Locale;
  };
} = {
  nl: { nativeName: 'Nederlands', locale: nl },
  en: { nativeName: 'English', locale: enGB },
  de: { nativeName: 'Deutsch', locale: de },
  fr: { nativeName: 'Français', locale: fr },
};

const initializeI18n = async () => {
  await i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      supportedLngs: Object.keys(lngs),
      fallbackLng,
      backend: {
        backends: [
          resourcesToBackend(async (language: string, namespace: string) => {
            if (!globalAuth.value?.accessToken || !globalAuth.value?.webshopKey) return; // Force fallback

            // Also load the local translation to allow for falling back to it on a per key basis
            const [fromApi, localResponse] = await Promise.all([
              apiClient.translations({ params: { lng: language } }),
              fetch(`/locales/${language}/${namespace}.json`), // Cached by the browser
            ]);
            const local = (await localResponse.json()) as Record<string, string>;

            return { ...local, ...fromApi };
          }),
          HttpBackend, // fallback for guests (i.e. not yet signed in)
        ],
        backendOptions: [{ loadPath: '/locales/{{lng}}/{{ns}}.json' }],
      },
      defaultNS: 'translation',
    });
};

initializeI18n().catch((error) => {
  // eslint-disable-next-line no-console
  console.error('Error initializing i18n:', error);
});

export default i18n;
