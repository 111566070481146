import { z } from 'zod';

import { openItemBaseSchema } from '../schemas/item.schema';
import { messageSchema } from '../schemas/message';
import { addDatesTransformFn, addTypeNameFn } from '../schemas/utils.schema';

export const openItemResponse = openItemBaseSchema.transform(addDatesTransformFn).transform(addTypeNameFn('openItem'));

export const openItemActionResponse = z.object({
  error: z.boolean(),
  messages: z.array(messageSchema).nullish(),
});
