import { z } from 'zod';

import { dateSchema } from './utils.schema';

export const newsItemSchema = z.object({
  id: z.coerce.number().int().positive(),
  title: z.string(),
  message: z.string(),
  date: dateSchema,
  highlight: z.boolean(),
});

export type NewsItem = z.infer<typeof newsItemSchema>;
