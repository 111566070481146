import * as Sentry from '@sentry/react';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ZodSchema } from 'zod';

export const LocalStorageKey = {
  Auth: 'auth',
  HidePriorityMessage: 'hide-priority-message',
} as const;

export type LocalStorageKey = (typeof LocalStorageKey)[keyof typeof LocalStorageKey];

export const useLocalStorage = <T>(
  keyName: LocalStorageKey,
  defaultValue: T | null = null,
  zodSchema?: ZodSchema<T>,
) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        if (zodSchema) {
          return zodSchema.parse(JSON.parse(value));
        } else {
          return JSON.parse(value) as T;
        }
      } else {
        if (defaultValue === null) {
          window.localStorage.removeItem(keyName);
        } else {
          window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        }
        return defaultValue;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      Sentry.captureException(err);
      return defaultValue;
    }
  });

  const setValue: Dispatch<SetStateAction<T | null>> = useCallback(
    (action) => {
      setStoredValue((current) => {
        const newValue = action instanceof Function ? action(current) : action;

        try {
          if (newValue === null) {
            window.localStorage.removeItem(keyName);
          } else {
            window.localStorage.setItem(keyName, JSON.stringify(newValue));
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          Sentry.captureException(err);
        }
        return newValue;
      });
    },
    [keyName],
  );

  return [storedValue, setValue] as const;
};
