import { z } from 'zod';

import { filterSchema } from '../schemas/filter.schema';
import { filterGroupObjectTypeSchema } from '../schemas/filterGroup.schema';
import { groupSchema } from '../schemas/group.schema';
import { itemSchema } from '../schemas/item.schema';
import { messageSchema } from '../schemas/message';
import { schematicPeriodSchema } from '../schemas/schematicPeriod.schema';

export const filterGroupResponse = z.object({
  filters: z.array(filterSchema),
  groups: z.array(groupSchema),
  icon: z.string().nullable(),
  id: z.number(),
  items: z.array(itemSchema),
  maxPlanningDate: z.string().nullable(),
  messages: z.array(messageSchema).nullish(),
  name: z.string(),
  objectType: filterGroupObjectTypeSchema,
  schematicPeriods: z.array(schematicPeriodSchema),
  timeLineFrom: z.string().optional(),
  timeLineTo: z.string().optional(),
});

export type FilterGroupResponse = z.infer<typeof filterGroupResponse>;
