import { Zodios } from '@zodios/core';
import z from 'zod';

import { env } from '~/env';

import { sentryPlugin } from './api';
import { ssoResponse } from './endpoints/sso';

export const ssoClient = new Zodios(env.VITE_SSO_URL, [
  {
    method: 'get',
    path: '/webshop_sso.php',
    alias: 'sso',
    description:
      'The app or website that opens this app will provide an SSO string. This query will validate that sso-string returning credentials for all subsequent requests.',
    parameters: [
      {
        name: 'ssostring',
        type: 'Query',
        schema: z.string(),
      },
    ],
    response: ssoResponse,
  },
]);

ssoClient.use(sentryPlugin);
