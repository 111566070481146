import z from 'zod';

import { contactSchema } from '../schemas/contact.schema';
import { messageSchema } from '../schemas/message';
import { newsItemSchema } from '../schemas/newsItem.schema';
import { sectionSchema } from '../schemas/section.schema';
import { filterGroupsResponse } from './filterGroups';

export const dashboardResponse = z.object({
  cartContactType: z.enum(['internal', 'external']),
  contact: contactSchema,
  error: z.boolean().nullable(),
  filterGroups: filterGroupsResponse,
  hasActiveCart: z.boolean(),
  hasClubList: z.boolean(),
  hasClubSchematic: z.boolean(),
  hasOpenItems: z.boolean(),
  hasPastItems: z.boolean(),
  messages: z.array(messageSchema).nullish(),
  mode: z.enum(['list', 'schematic']),
  modeFilterGroupId: z.string().nullable(),
  newsItems: z.array(newsItemSchema),
  section: sectionSchema,
  webshopKey: z.string().nullable(),
});

export type Dashboard = z.infer<typeof dashboardResponse>;
