import { GuestPlayer, Player } from '~/api/schemas/contact.schema';

export const guestKey = 'guest';

/**
 * Removes a player from the list of players by `key`. Returns a new list of players.
 * Note that multiple players with a `key` of `guest` can appear in a list, only one of these will be removed.
 */
export const removePlayer = (players: (Player | GuestPlayer)[] = [], key: string): (Player | GuestPlayer)[] => {
  if (key === guestKey) {
    const index = players.findIndex((player) => player.key === key);
    return [...players.slice(0, index), ...players.slice(index + 1)];
  }
  return players.filter((player) => player.key !== key);
};

export const guest: GuestPlayer = {
  photoLink: null,
  name: null,
  key: guestKey,
  photoAlternative: 'IN',
  checkedIn: false,
  actions: [],
};
