import { Box, Button, Typography } from '@mui/material';
import { WarningCircle } from '@phosphor-icons/react';
import axios from 'axios';
import { Component, ErrorInfo, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AppRoutes from './routes';

interface Props extends WithTranslation {
  children?: ReactNode;
  error?: Error | null;
}

interface State {
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public componentDidCatch(_error: Error, _errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.debug('Something unexpected had happened', _error, _errorInfo);
  }

  render() {
    const { t } = this.props;

    const error = this.state.error || this.props.error;

    if (error) {
      const errorString =
        axios.isAxiosError(error) && error?.response?.data?.message ? error?.response?.data?.message : error.toString();

      return (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '135px',
              minHeight: '135px',
              borderRadius: 100,
              backgroundColor: 'other.plainWhite',
            }}
          >
            <WarningCircle color="#7474F3" size={88} weight="fill" />
          </Box>
          <Typography
            sx={{
              marginTop: 2,
              marginBottom: 4,
              color: 'text.secondary',
            }}
            variant="body/regular"
          >
            {t('error.error-boundary.msg')} {errorString}
          </Typography>
          <Link to={AppRoutes.Dashboard}>
            <Button>{t('common.home-btn')}</Button>
          </Link>
        </>
      );
    }

    // Otherwise show children
    return this.props.children;
  }
}

// eslint-disable-next-line react-refresh/only-export-components
export default withTranslation('translation')(ErrorBoundary);
