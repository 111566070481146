import { z } from 'zod';

const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
const hexColorMessage = 'Invalid hex color';

export const stylingResponse = z.object({
  primary: z.object({
    main: z.string().regex(hexColorRegex, hexColorMessage),
    light: z.string().regex(hexColorRegex, hexColorMessage).optional(),
    dark: z.string().regex(hexColorRegex, hexColorMessage).optional(),
  }),
  secondary: z.object({
    main: z.string().regex(hexColorRegex, hexColorMessage),
    light: z.string().regex(hexColorRegex, hexColorMessage).optional(),
    dark: z.string().regex(hexColorRegex, hexColorMessage).optional(),
  }),
  text: z
    .object({
      primary: z.string().regex(hexColorRegex, hexColorMessage),
      secondary: z.string().regex(hexColorRegex, hexColorMessage),
    })
    .optional(),
  background: z
    .object({
      default: z.string().regex(hexColorRegex, hexColorMessage),
    })
    .optional(),
});
