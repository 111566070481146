import z from 'zod';

import { cartActionSchema } from '../schemas/action.schema';
import { cartLineItemSchema } from '../schemas/item.schema';
import { messageSchema } from '../schemas/message';

type ItemWithKey = { key: string };
type ItemWithoutKey = { key: null };
type Item = ItemWithKey | ItemWithoutKey;
const hasKey = <T>(item: T & Item): item is T & ItemWithKey => item.key !== null;
const withKeys = <T>(items: (T & Item)[]): (T & ItemWithKey)[] => items.filter(hasKey);

export const cartResponse = z.object({
  actions: z.array(cartActionSchema),
  lines: z.array(cartLineItemSchema).transform(withKeys),
  messages: z.array(messageSchema).nullish(),
});

export type Cart = z.infer<typeof cartResponse>;

export const cartConfirmResponse = z.object({
  error: z.boolean(),
  messages: z.array(messageSchema).nullish(),
});

export type CartConfirm = z.infer<typeof cartConfirmResponse>;
